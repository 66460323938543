<template>
    <div>
        <top>
            <div class="tc cfff">
                <div class="fs42 pt140">人才招聘</div>
                <div class="fs20 mt20 " style="opacity: 0.8;">有关安迩平台人才招聘</div>
            </div>
        </top>
        <div class="rowjc" style="margin-top: -130px; ">
            <div class="box" style="padding: 0px 40px; min-height: calc(100vh - 670px ); ">
                <div class="fs28 c333 pt30 fwb yc1">{{info.title}}</div>
                <div class="fs16 c999 mt15">{{info.createTime}}</div>
                <div class="mt36 pb30  c666" v-html="info.content">
                   
                </div>
            </div>
        </div>
        <div style="height:60px;"></div>
       
        <bottom ></bottom>
       
    </div>
</template>
<script>
    import top from '@/components/top'
    import bottom from '@/components/common/bottom'
    export default {
        components: {
            bottom, top
        },
        data() {
            return {
                info:{}
            }
        },
        created() {

        },
        mounted() {
            console.log(this.$route.query.id)
            this.getInfo(this.$route.query.id)
        },
        methods: {
            getInfo(id){
                this.$axios({
                    url:'getMessageInfo',
                    data:{
                        id
                    }
                }).then(res=>{
                    console.log(res.data)
                    if(res.data.code==0){
                        this.info=res.data.data
                    }else{
                        this.info={}
                    }
                })
            }

        },
    }
</script>
<style>
    #app {
        background-color: #F2F5F7;
    }

    .box {
        width: 1200px;
        background: #FFFFFF;
        border-radius: 20px;

    }

    .bottomQ:not(:last-child) {
        border-bottom: 1px dashed #D7D7D7;
    }

    .bottomQ:hover {
        cursor: pointer;
    }
</style>